//viewQuotation
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import { ToWords } from "to-words";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  TableContainer,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import "./viewQuotation.css";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";
import { viewQuotationGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import CustomPDFViewer from "../trainingResources/CustomPDFViewer";
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  cell50: {
    padding: 1,
    width: "50%",
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 50,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 30,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important",
  },
});

let termsAndCondition = `1. You are requested to confirm your acceptance through PO 2. Please arrange to deliver the instruments through authorized courier agency/by hand delivery at Belz HO address 3. Ensure proper packing while delivering the instruments. In case of any loss or damage during the transit Belz will not be responsible for any claim 4. If your instrument is out of order and cannot be calibrated, we will intimate you the same and payment for handling charges, freight, insurance, and packing charges (30%) will be borne by the customer 5. Repair: Before taking up repair activity, we will provide you quotation for repairing. Only after approval repairing will be carried out 6. Priority Services: On your demand we can provide calibration on the same day/Sunday or night shifts 7. Validity of this quotation is for 60 days from the date of issue 8. The customer needs to inform in advance due date to be incorporated in the certificate otherwise Belz will do as per there standard procedure and any amendment later after release of the certificate will be on chargeable basis 9. Instruments accepted for calibration can be returned uncalibrated under circumstances beyond our control. Charges quoted are for calibration of instruments and are payable even if they are out of calibration 10. Site visit charges will be applicable for on site calibration 11. Our quotation reference number should be there on your purchase order (PO)
`

function InstrumentTable({
  classes,
  quotationProductDetails,
  productList1,
  quotationAllData,
}) {
  const toWords = new ToWords();
  let productDeatails = quotationProductDetails;
  let productList = productList1;
  const hasDiscount =
    Array.isArray(productDeatails) &&
    productDeatails?.some((product) => product?.discount !== null);

  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    courierCharges: 0,
    grandTotal: 0,
    discount: 0,
    totalAmountbeforediscount: 0,
  };

  if (productDeatails.length > 0 && productList.length > 0) {
    for (let i = 0; i < productDeatails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDeatails[i].equipmentId
      );
      productDeatails[i].name = result?.instrumentName;
      let totalAmount = productDeatails[i].rate * productDeatails[i].quantity;
      let discountAmount =
        (totalAmount * (parseFloat(productDeatails[i]?.discount) || 0)) / 100;
      productDeatails[i].amount = totalAmount - discountAmount;
      amounts.totalAmountBeforeTax += productDeatails[i].amount;
    }
    amounts.conveyanceCharges = parseInt(
      quotationAllData[0]?.conveyanceCharges
        ? quotationAllData[0]?.conveyanceCharges
        : "0"
    );
    amounts.courierCharges = parseInt(
      quotationAllData[0]?.courierCharges
        ? quotationAllData[0]?.courierCharges
        : "0"
    );
    amounts.totalAmountBeforeTax +=
      amounts.conveyanceCharges + amounts.courierCharges;

    amounts.totalAmountbeforediscount = amounts.totalAmountBeforeTax;
    amounts.discount = quotationAllData[0]?.discount;
    let discountcal = (amounts.totalAmountBeforeTax * amounts.discount) / 100;

    amounts.totalAmountBeforeTax = amounts.totalAmountBeforeTax - discountcal;

    const gstType = quotationAllData[0]?.gstType;
    const gstPercentages = quotationAllData[0]?.gstpercentages;

    if (gstType === 1) {
      const percentage = gstPercentages ? gstPercentages / 2 : 9;
      amounts.sgst = amounts.cgst =
        (amounts.totalAmountBeforeTax * percentage) / 100;
      amounts.gst = amounts.sgst + amounts.cgst;
    } else if (gstType === 2) {
      amounts.gst =
        (amounts.totalAmountBeforeTax * (gstPercentages || 18)) / 100;
    } else {
      amounts.gst = 0;
    }
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
  }

  return (
    <Table mt={5} className={["single-border-table", classes.table]}>
      <TableRow style={{  }} id="pagbreaksborder">
        <TableCell className={classes.srCell} align="center">
          <b>Sr. No</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b>Description of Item | Equipment - Range</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b>Description</b>
        </TableCell>
        <TableCell className={classes.makeCell} align="center">
          <b>SAC | HSN Code Job Type</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b>Type of Service</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b>Rate</b>
        </TableCell>
        <TableCell className={classes.qntCell} align="center">
          <b>Quantity (No)</b>
        </TableCell>
        {hasDiscount && (
          <TableCell className={classes.qntCell} align="center">
            <b>Discount (%)</b>
          </TableCell>
        )}
        <TableCell className={classes.rateCell} align="center">
          <b>Total Price (INR)</b>
        </TableCell>
      </TableRow>
      {productList &&
        productDeatails?.map((product, i) => (
          <TableRow className={classes.row} key={i} id="pagbreaksborder">
            <TableCell className={classes.srCell} align="center">
              {i + 1}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
              {product?.requestedName ? product?.requestedName : product?.name}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
            {product?.rangeValue?.replace(/#/g, ' ')?.replace(/\|/g, ' to ')?.replace(/\s+/g, ' ')?.trim()}  &nbsp;   {product?.description}
            </TableCell>
            <TableCell className={classes.makeCell} align="center">
              {product?.hsnCode}
            </TableCell>
            <TableCell className={classes.rangeCell} align="center">
              {product?.service}
            </TableCell>
            <TableCell className={classes.qntCell} align="center">
              {product?.rate?.toFixed(2)}
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              {product?.quantity}
            </TableCell>
            {hasDiscount ? (
              product?.discount !== null ? (
                <TableCell className={classes.rateCell} align="center">
                  {product?.discount} %
                </TableCell>
              ) : (
                <TableCell
                  className={classes.rateCell}
                  align="center"
                ></TableCell>
              )
            ) : null}
            <TableCell className={classes.rateCell} align="center">
              {product?.amount?.toFixed(2)}
            </TableCell>
          </TableRow>
        ))}
      {amounts.conveyanceCharges !== 0 && (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b>Onsites Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>
              {" "}
              {amounts.conveyanceCharges
                ? amounts.conveyanceCharges?.toFixed(2)
                : 0}
            </b>
          </TableCell>
        </TableRow>
      )}
      {amounts.courierCharges !== 0 && (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b> Courier Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>
              {amounts.courierCharges ? amounts.courierCharges?.toFixed(2) : 0}
            </b>
          </TableCell>
        </TableRow>
      )}
       <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="right"
        >
          <b className={classes.boldCss}>Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b className={classes.boldCss}>{amounts.totalAmountbeforediscount?.toFixed(2)}</b>
        </TableCell>
      </TableRow>
      {amounts?.discount ? (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b>Discount&nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>{amounts?.discount ? `${amounts?.discount} %` : ""}</b>
          </TableCell>
        </TableRow>
      ) : (
        ""
      )}
       {amounts?.discount && (
      <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="right"
        >
          <b>Total Amount After Discount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.totalAmountBeforeTax?.toFixed(2)}</b>
        </TableCell>
      </TableRow>
      )}
      {quotationAllData?.[0]?.gstType === 1 && (
        <>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b>{`CGST(${(quotationAllData?.[0]?.gstpercentages || 18) / 2}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b>{(amounts?.gst / 2)?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b>{`SGST(${(quotationAllData?.[0]?.gstpercentages || 18) / 2}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b>{(amounts?.gst / 2)?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </>
      )}
      {(quotationAllData?.[0]?.gstType === 2 ) && (
        <>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b>{`IGST(${quotationAllData?.[0]?.gstpercentages || 18}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b>{amounts.gst?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow style={{ }} id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="right"
        >
          <b>Grand Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : ""}</b>
        </TableCell>
      </TableRow>
      <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 9 : 8}
          align="left"
        >
          <div>
            <b style={{ padding: "1px 5px" }}>
              &nbsp; Amount in Words:
            </b>
            <b>
              &nbsp;
              {toWords.convert(
                amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : 0,
                { currency: true }
              )}
            </b>
          </div>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default function EditQuotation() {
  const viewQuotationGuides = viewQuotationGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [asPerAttach, setAsPerAttach] = React.useState(false);
  const [details, setDetails] = React.useState({
    companyName: "",
    companyNumber: "",
    clientName: "",
    address: "",
    referenceNumber: "",
    quotationNumber: "",
    subject: "",
    Date: "",
    thankYouForEnquiry: "",
    termsConditions: "",
    clientId: "",
    conveyanceCharges: "",
    gstNumber: "",
    email: "",
  });
  const [quotationProductDetails, setProductDetails] = React.useState([]);
  const [quotationAllData, setquotatioAllData] = React.useState([]);
  const [settings, setSettings] = React.useState({});
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [contact, setContact] = React.useState({
    contname: "",
    email: "",
    contactNumber: "",
  });
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [88, 1, 65, 1],
          filename: `certificate_${params.id}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 2.5,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: 'png', quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

         // border add for contnet
         const borderWidth = 0.6;
         const leftMargin = 15;
         const borderX = leftMargin;
         const borderY = 85;
         const rightMargin = 595 - leftMargin * 0.95;
         pdf.setLineWidth(borderWidth);
         pdf.rect(borderX, borderY, rightMargin - leftMargin, 690);

        // let image = await generateDynamicHeader(
        //   ulrNumber,
        //   certificateNumber,
        //   i,
        //   totalPages
        // );
        // pdf.addImage(image, 0, 70, 595, 55);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 50, 595, 55);

        if (letterHead) {
          let image = await generateLetterHeadHeader();
          pdf.addImage(image, 0, 0, 595, 70);

          image = await generateLetterHeadFooter();
          pdf.addImage(image, 0, 785, 595, 58);
        }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${quotationAllData[0]?.companyName}_${params.quotationNumber}.pdf`;
      a.click();
      // pdf.save();
      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  function fetchSettings() {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      let _settingList = {};
      res.data.forEach((setting) => {
        _settingList[setting.keyName] = setting.value;
      });
      setSettings(_settingList);
    }).catch((err)=>{
      if (err.message !== "request_aborted") {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSettings" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
      }
    })
  }
  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from instrumentPriceList as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getQuat = () => {
    let url = BASE_URL;
    let paylaod = {
      query: `SELECT qt.*,qt.address as qt_address,qt.id as qt_id, client.* FROM quotation AS qt JOIN clients AS client ON qt.clientId = client.id WHERE qt.clientId = ${params.clientId} AND qt.quotationNumber LIKE '${params.quotationNumber}'`,
    };
    axiosWithToken
      .post(url + "dynamic", paylaod)
      .then(async (res) => {
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${res.data[0]?.qt_id}`,
        };
        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        setProductDetails(response.data);
        let contact = res.data[0].contact
          ? JSON.parse(res.data[0].contact)
          : null;
        setquotatioAllData(res.data);
        setContact({
          contname: contact && contact[0]?.contactPersonName || "",
          email: contact && contact[0]?.emailId || "",
          contactNumber: contact && contact[0]?.contact || "",
        });
      })
      .catch((err) => {
        console.log("getquat", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getQuat" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
        }
      });
  };
  useEffect(() => {
    // getQuotations();
    fetchSettings();
    getProductList();
    getQuat();
  }, []);

  useEffect(async () => {
    let splitAddress = quotationAllData[0]?.qt_address?.split(",");
    let newAddressArray = await fetchAddresses(
      splitAddress?.[0],
      splitAddress?.[1]
    );
    setNewAddressArray(newAddressArray);
  }, [quotationAllData[0]?.qt_address]);

  return (
    <>
      <Paper sx={{ mx: 5, p: 2 }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                viewQuotationGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view_letterhead"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view__electronicsign"
              options={[
                { id: 1, label: "With Electronically Signed" },
                { id: 2, label: "Without  Electronically Signed" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Electronically Signed" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Electronically Signed" ||
                  newInputValue === ""
                ) {
                  setElectronicSign(true);
                } else if (newInputValue === "Without  Electronically Signed") {
                  setElectronicSign(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Autocomplete
                    size="small"
                    options={[
                      { id: 1, label: "As per attached list" },
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="As per attached list" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue === "As per attached list" ||
                        newInputValue === ""
                      ) {
                        setAsPerAttach(true);
                      }
                    }}
                  />
                </Grid>
          <Grid item xs={3}>
            <Button
              id="quotation_view_Download"
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
        <br />
        <div
          className="invoice"
          ref={printComponentRef}
          id="quotation_view__print"
        >
          {quotationAllData ? (
            <div
              style={{
                width: "95%",
                // border: "1px solid black",
                textAlign: "center",
                margin: "0px auto",
                padding: "10px 10px 5px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  md={12}
                  className="font-link"
                  align="center"
                  justifyContent={"center"}
                >
                  <h5 style={{ color: "#000066", margin: "5px 0px" }}>
                    <b>QUOTATION FOR CALIBRATION & TESTING SERVICES</b>
                  </h5>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, fontSize: "0.875rem", fontWeight: 400 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                        
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Customer</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none" }}
                      >
                        {quotationAllData[0]?.companyName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                        
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Enquiry ID | Date</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                       {quotationAllData[0]?.enquiryId} | {quotationAllData[0]?.enquiryDate ? moment(quotationAllData[0]?.enquiryDate).format(
                          "DD/MM/YYYY"
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                        
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Billing Address</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none" }}
                      >
                        {newAddressArray?.[0]?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                        
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Quotation Number | Date</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                        {quotationAllData[0]?.quotationNumber + (quotationAllData[0]?.revisionNumber > 0 ? `-Rev-${quotationAllData[0]?.revisionNumber}` : "") } |{" "}
                        {moment(quotationAllData[0]?.date).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                        
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Contact Person</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none" }}
                      >
                        {contact?.contname}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                        
                          borderRight: "none",
                          width: "22%",
                        }}
                      >
                        <b>Contact Number</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                        {contact?.contactNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                        
                          borderRight: "none",
                        }}
                      >
                        <b>E-mail</b>
                      </TableCell>
                      <TableCell align="left" style={{ borderRight: "none" }}>
                        {contact?.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                        
                          borderRight: "none",
                        }}
                      >
                        <b>Reference Number</b>
                      </TableCell>
                      <TableCell align="left">
                        {quotationAllData[0]?.referenceNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                        
                          borderRight: "none",
                        }}
                      >
                        <b>Quotation Validity</b>
                      </TableCell>
                      <TableCell align="left" style={{ borderRight: "none" }}>
                        {quotationAllData[0]?.quotationValidity}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                        
                          borderRight: "none",
                        }}
                      >
                        <b>Equipment Delivery</b>
                      </TableCell>
                      <TableCell align="left">
                        {quotationAllData[0]?.equipmentDelivery}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                        
                          borderRight: "none",
                        }}
                      >
                        {newAddressArray?.[0]?.shipping?.address ? (
                          <b>Shipping Address</b>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderRight: "none",
                        }}
                      >
                        {newAddressArray?.[0]?.shipping?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "600",
                        
                          borderRight: "none",
                        }}
                      >
                        <b>Equipment Collection</b>
                      </TableCell>
                      <TableCell align="left">
                        {quotationAllData[0]?.equipmentCollection}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <div
                style={{
                
                  textAlign: "left",
                  border: "1px solid black",
                  padding: "3px 10px",
                  display: "flex",
                  gap: "0px",
                  flexDirection: "column",
                  fontSize: "13px",
                  borderBottom: "none",
                }}
              >
                <b>Dear Customer:</b>
                <b style={{ fontWeight: 500 }}>
                  Thank you for the enquiry. Please contact our Business
                  Associate for any further assistance on Calibration | Testing
                  Services at:
                </b>
                <b>
                  Enquiry: +91 98406 72352 | Quotation: +91 99405 64783 |
                  Operations: +91 99400 47321
                </b>
              </div> */}

              {asPerAttach && (
                <table
                  style={{
                    width: "100%",
                    margin: "0px auto",
                    fontSize: "13px",
                  }}
                  className="srftable"
                >
                  <thead>
                    <tr>
                      <th colSpan={11} style={{ padding: "5px" , borderBottom:"none"}}>
                        <b>QUOTATION DETAILS AS PER ATTACHED LIST</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}

              <InstrumentTable
                classes={classes}
                quotationProductDetails={quotationProductDetails}
                productList1={productList}
                quotationAllData={quotationAllData}
              ></InstrumentTable> 
              
            <div
              style={{
                margin: "0 auto",
                fontFamily: "Arial, sans-serif",
                fontSize: "14px",
                lineHeight: "1.6",
                textAlign: "left",
              }}
            >
              <h6
                style={{
                  textDecoration: "underline",
                  marginBottom: "8px",
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                Remarks :
              </h6>
              <ul style={{ margin: 0, paddingLeft: "20px" }}>
                <li>
                  1). We will issue calibration certificate with "NABL Symbol" for the
                  instruments which will be covered under our "NABL Scope" otherwise
                  calibration certificate with "NON - NABL" Will be issued.
                </li>
                <li>
                  2). The attached annexure duly filled in is mandatory to submit along
                  with Purchase Order.
                </li>
              </ul>

              <h6
                style={{
                  textDecoration: "underline",
                  marginBottom: "8px",
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "10px",
                }}
              >
                Notes:
              </h6>
              <ul style={{ margin: 0, paddingLeft: "20px" }}>
                <li>
                  * Calibration is done for scientific purpose only and shall not be
                  produced in the court of law
                </li>
                <li>
                  * Belz will not be responsible for instruments damaged during transit or
                  loss
                </li>
                <li>* Ensure instruments are covered under our NABL scope</li>
              </ul>

              <div style={{ margin: "16px 0" }}>
                <strong>SAC Code:</strong> 998346 - TECHNICAL, TESTING &amp; ANALYSIS
              </div>

              <div style={{ margin: "16px 0", pageBreakInside: "avoid" }}>
                <h6 style={{ margin: 0 }}>
                  TDS: TDS should be deducted @ 2% under section 194C. If TDS is applicable,
                  TDS Certificate should be issued within one month of deduction else Belz
                  shall raise a debit note and also notify relevant authorities.
                </h6>
                <h6 style={{ margin: "8px 0 0 0" }}>
                  ESIC REG. NO: 13001899770000099 &nbsp;&nbsp; PF NO: BR HFO 0011738000
                </h6>
              </div>

              <h6
                style={{
                  marginBottom: "8px",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Beneficiary Details:
              </h6>
              <div style={{ marginBottom: "8px" }}>
                <strong>Beneficiary Name:</strong> BELZ INSTRUMENTS PRIVATE LIMITED
              </div>
              <div style={{ marginBottom: "4px", pageBreakInside: "avoid" }}>
                <strong>BANK OF BARODA:</strong> Sector-7 Branch, Faridabad <br />
                <strong>Account Number:</strong> 07870020001006, &nbsp;
                <strong>IFSC Code:</strong> BARB0SECA7F, &nbsp;
                <strong>MICR Code:</strong> 110012057
              </div>
              <div style={{ marginBottom: "16px", pageBreakInside: "avoid" }}>
                <strong>AXIS BANK:</strong> 5R-1/5/2, Ground Floor, NIT-5, Faridabad 121001{" "}
                <br />
                <strong>CC Account #:</strong> 922030048912691, &nbsp;
                <strong>IFSC Code:</strong> UTIB0000002, &nbsp;
                <strong>MICR Code:</strong> 110211002
              </div>

              <div
                style={{
                  margin: "40px 0",
                  padding: "1px",
                  borderTop: "1px solid black",
                  pageBreakInside: "avoid",
                }}
              >
                <h6 style={{ margin: 0 }}>
                  Please indicate your acceptance by signing &amp; stamping and email/fax
                  back to us:
                </h6>
                <div
                    style={{
                      width: "30%",
                      marginLeft: "auto", 
                      borderTop: "1px solid #000",
                      textAlign: "center",
                      marginTop:"15px"
                    }}
                  >
                   <h6 style={{ margin: 0 }}>
                 P.O.No
                </h6>
                  </div>

              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  pageBreakInside: "avoid",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    marginRight: "16px",
                    borderTop: "1px solid #000",
                    textAlign: "center",
                  }}
                >
                  <strong>Name</strong>
                </div>

                <div
                  style={{
                    flex: 1,
                    marginRight: "16px",
                    borderTop: "1px solid #000",
                    textAlign: "center",
                  }}
                >
                  <strong>Authorised Signature/Date</strong>
                </div>

                <div
                  style={{
                    flex: 1,
                    borderTop: "1px solid #000",
                    textAlign: "center",
                  }}
                >
                  <strong>Company Stamp</strong>
                </div>
              </div>
            </div>
           

              <div
                style={{
                  fontSize: "13px",
                  pageBreakInside: "avoid",
                  paddingTop: "2px",
                  marginTop:"50px", 
                }}
              >
                <div >
                  <h6>
                    <b>TERMS AND CONDITIONS</b>
                  </h6>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      padding: "1px 5px",
                      textAlign: "justify",
                    }}
                  >
                     <h6 style={{marginTop:"20px", textAlign:"center"}}>
                    <b>IT IS MANDATORY TO FILL ATTACHED SERVICE REQUEST FORM AND SEND IT WITH PURCHASE ORDER</b>
                  </h6>
                    {termsAndCondition?.replace(/(\d+)\./g, "\n $1. ")}
                  </div>
                </div>
              </div>

                {asPerAttach && (
                  <ResourceModel
                resourceUrl={quotationAllData[0]?.fileUpload}
                />
                )}
            </div>
          ) : (
            <div>Loading...</div>
          )}

          <div style={{ width: "100%" }} id="header-section"></div>
        </div>
        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Quotation"}
          />
        )}
          {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
      </Paper>
    </>
  );
}

function ResourceModel({ resourceUrl }) {
  const isPDF = resourceUrl && resourceUrl?.endsWith(".pdf");
  const isImage =
    resourceUrl &&
    (resourceUrl?.endsWith(".jpg") ||
      resourceUrl?.endsWith(".jpeg") ||
      resourceUrl?.endsWith(".png"));

  return (
    <div style={{ margin: "3px", pageBreakBefore:"always" }}>
        <WatermarkedContent>
        {isPDF ? (
          <CustomPDFViewer url={resourceUrl} height="100%" canvasHeight={"900px"} />
        ) : isImage ? (
          <img
            src={resourceUrl}
            alt="Resource"
            style={{ width: "100%", height: "100%" }}
          />
        ) : null // Return null for unsupported resource types
        }
        </WatermarkedContent>
    </div>
  );
}

const WatermarkedContent = ({ children }) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {children}
    </div>
  );
};