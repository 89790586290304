import React from "react";
import { ClassicTable } from "../../utils/components/Styles";
import { MAX_COLS, BASE_URL } from "../../global";
import { withStyles } from "@material-ui/core/styles";
import { TableRow } from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const BlackBorderTableCell = withStyles({
  root: {
    border: "1px solid black !important",
    borderBottomWidth: "0px !important",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

export default function RenderStaticTables({
  tableDisplayStatus,
  configuration,
  certificateId,
  instrumentId,
  deletedTableIds,
  tableDivide,
}) {
  const [staticReadingRows, setStaticReadingRows] = React.useState([]);
  const [selectedRangeIds, setSelectedRangeIds] = React.useState([]);
  const [staticCertificateReadings, setStaticCertificateReadings] =
    React.useState([]);
    const [tables, setTables] = React.useState(null);
    const [tableName, setTableName] = React.useState("");
    const [unitName, setUnitName] = React.useState("");
    const [staticTables, setStaticTables] = React.useState([]);

  function fetchCertificateStaticTables(instrumentId, deletedTableIds) {
    deletedTableIds = deletedTableIds?.split(",")?.map((id) => Number(id));
    axiosWithToken
      .get(
        BASE_URL +
          `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (deletedTableIds) {
          res.data = res.data.filter(
            (row) => !deletedTableIds.includes(row.id)
          );
        }
         res.data = res.data.map((table) => {
          return {
            ...table,
            "defaultConfiguration": JSON.parse(table?.defaultConfiguration || null)
          }
        })
        setStaticTables(res.data);
      })
      .catch((err) => {
        console.log("datasheet static tables data fetching error: ", err);
      });
  }

  function fetchStaticReadingRows() {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `datasheetStaticReadings?_where=(datasheetId,eq,${certificateId})`
      )
      .then((res) => {
        setStaticCertificateReadings(res.data);
      })
      .catch((err) => {
        console.log("static certificate tables data fetching error: ", err);
      });
  }

  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    let hasCommonHeader = defaultConfiguration?.hasCommonHeader;
    if (hasCommonHeader) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig?.forEach((col) => {
        let [name, size] = col?.split(":");
        cols.push(
          <BlackBorderTableCell
            style={{ textAlign: "center" }}
            colSpan={Number(size)}
            className={`${name != "$" ? "common-border" : ""}`}
          >
            <b>{name != "$" ? name : ""}</b>
          </BlackBorderTableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          <BlackBorderTableCell align="center"></BlackBorderTableCell>
          {cols}
        </TableRow>
      );
    }
    return null;
  };

  const getTotalUnitRows = (staticReadingRows, tableId) => {
    let count = 0;
    count = staticReadingRows
      ?.filter((e) => e[0] == tableId)
      ?.some((row) => {
        if (row[2]?.includes("_unit_")) count += 1;
        if (count > 1) return true;
      });
    if (count) count = 2;
    else count = 0;
    return count;
  };

  const renderTableCell = (
    table,
    row,
    rowIndex,
    cell,
    cellIndex,
    current_row_units = null
  ) => {
    let field = null;
    if (String(cell)?.includes("_unit_")) {
      // if (table["c" + (cellIndex + 1)]?.toLowerCase()?.match(/m+\d+/g)) {
      //   field = <div></div>;
      // } else {
      let unit = cell.split("_unit_")[1];
      field = (
        <span>
          <b>{unit}</b>
        </span>
      );
      // }
    } else if (cell && cell[0] == "$") {
      field = (
        <span>
          {!isNaN(cell.slice(1))
            ? cell.slice(1)?.replaceAll("#"," ")
            : cell.slice(1)?.replaceAll("#"," ")}
        </span>
      );
    } else if (cell && cell == "null") {
      field = (
        <span>
          {!isNaN(cell.slice(1)) ? Number(cell.slice(1)).toFixed(2)?.replaceAll("#"," ") : ""}
        </span>
      );
    } else {
      let unit = current_row_units?.[cellIndex + 2]?.split("_unit_")[1];
      if (unit?.length > 0) unit = " " + unit;
      else unit = "";
      field = (
        <span>
          {row[cellIndex + 2]?.split("#")?.[0]}
          {unit}
        </span>
      );
    }
    return (
      <td
        key={cellIndex}
        className="tableCss"
        style={{
          textAlign: "center",
        }}
      >
        {field}
      </td>
    );
  };

  const getTableIndex = (tableId, isStatic = false) => {
    if (!isStatic) {
      for (let i = 0; i < tables.length; i++) {
        if (tables[i]["id"] == tableId) {
          return i;
        }
      }
    } else {
      for (let i = 0; i < staticTables.length; i++) {
        if (staticTables[i]["id"] == tableId) {
          return i;
        }
      }
    }
    return -1;
  };

  const initiateStaticReadingRows = () => {
    if (!(staticCertificateReadings && staticTables)) return null;

    let colsToSkip = [
      "id", // avoide duplicate
      "unitType",
      "instrumentId",
      "tableName",
      "parameterName",
      "defaultConfiguration",
      "conditionFormatting",
      "lastModified",
      "status",
      "isUncertainty",
    ];

    let readingRows = [];
    let tempStaticCertReadings = [...staticCertificateReadings];
    let _selectedRangeIds = selectedRangeIds;

    tempStaticCertReadings.forEach((reading, readingIndex) => {
      let tableIndex = getTableIndex(reading.tableId, true);

      if (tableIndex == -1) return null;
      let row = [];
      row.push(staticTables[tableIndex]["id"]);
      row.push(reading["id"]);
      const relations = staticTables[tableIndex]?.defaultConfiguration?.relations;
      
      if(!(reading["c1"]?.includes("_rh_"))){
          // reading row push
      for (let i = 0; i < MAX_COLS; i++) {
        
        let column = "c" + (i + 1)
        let datasheetColumn = relations?.[column]
        if (relations?.[column]) {
          let cellValue = reading?.[datasheetColumn]?.replaceAll("$"," ");
            row.push(
             reading?.[datasheetColumn] === "NA" ? "" : cellValue
          );
        } else {
          break;
        }
      }
      }
      else{
        for (let i = 0; i < staticTables[tableIndex]?.defaultConfiguration?.totalColumns; i++) {
          // _rh_ row push
          let column = "c" + (i + 1)          
            let cellValue = reading?.[column]?.replaceAll("$"," ");
              row.push(
               reading?.[column] === "NA" ? "" : cellValue
            );          
        }
      }      
      if (
        staticTables[tableIndex]["isUncertainty"] == 1 &&
        !staticTables[tableIndex].isVerticalUncertainty
      ) {
        row.push(reading["uncertainty"] ?? "null");
      }
      
      if (staticTables[tableIndex]["isComplianceStatus"] == 1) {
        if (reading["c1"].includes("_unit_")) {
          row.push("");
        } else {
          if (
            reading["complianceStatus"] === 1 ||
            reading["complianceStatus"] === "null"
          ) {
            row.push("Pass");
          } else if (reading["complianceStatus"] === 0) {
            row.push("Fail");
          }
        }
      }    
      
      readingRows.push(row);
      
      // set current ranges to selectedRangeIds
      _selectedRangeIds[readingIndex] = reading.standardRanges;

      setTableName(staticTables[tableIndex]["tableName"]);
      setUnitName(staticTables[tableIndex]["unitType"]);
    });
    setStaticReadingRows(readingRows);
  };

  const renderRows = (table, staticReadingRows) => {
    let i = 1;
    let currentUnitRow = [];

    let totalUnitRows = getTotalUnitRows(staticReadingRows, table.id);

    return [
      ...staticReadingRows?.map((row, rowIndex) => {
        if (row[0] == table.id) {
          if (row[2]?.includes("_rh_")) {
            i = 1;
            return (
              <tr
                key={rowIndex}
                height="14px"
                style={{
                  pageBreakInside: "avoid",
                  pageBreakAfter: "avoid",
                  pageBreakBefore: "avoid",
                }}
              >
                <td
                  className="tableCss"
                  colSpan={row.length - 2 + 1}
                  style={{ textAlign: "center", fontWeight: "400" }}
                >
                  {row[0 + 2].split("_rh_")[1]?.replaceAll("#"," ")}
                </td>
              </tr>
            );
          } else if (row[2]?.includes("_unit_")) {
            currentUnitRow = row;
            if (totalUnitRows < 2) {
              return (
                <tr
                  key={rowIndex}
                  height="14px"
                  style={{
                    pageBreakInside: "avoid",
                    pageBreakAfter: "avoid",
                    pageBreakBefore: "avoid",
                  }}
                >
                  <td className="tableCss" style={{ color: "black" }}>
                    
                    </td>
                  {row
                    .slice(2, row.length) // 2: tableId + datasheetId
                    .map((cell, cellIndex) =>
                      renderTableCell(table, row, rowIndex, cell, cellIndex)
                    )}
                </tr>
              );
            } else return null;
          }
          // else normal row
          return (
            <tr
              key={rowIndex}
              height="14px"
              id="pagbreaksborder"
              style={{
                pageBreakInside: "avoid",
                pageBreakAfter: "avoid",
                pageBreakBefore: "avoid",
              }}
            >
              <td className="tableCss">{i++}</td>
              {row
                .slice(2, row.length) // 2: tableId + datasheetId
                .map((cell, cellIndex) => {
                  if (totalUnitRows > 1)
                    return renderTableCell(
                      table,
                      row,
                      rowIndex,
                      cell,
                      cellIndex,
                      currentUnitRow
                    );
                  else
                    return renderTableCell(
                      table,
                      row,
                      rowIndex,
                      cell,
                      cellIndex
                    );
                })}
            </tr>
          );
        }
      }),
    ].concat(
      table.isUncertainty == 1 &&
        table.isVerticalUncertainty == 1 &&
        staticReadingRows &&
        staticReadingRows.length > 0
        ? [
            <tr
              key={staticReadingRows.length}
              height="14px"
              id="pagbreaksborder"
              style={{
                pageBreakInside: "avoid",
                pageBreakAfter: "avoid",
                pageBreakBefore: "avoid",
              }}
            >
              <td
                className="tableCss text-align-left"
                colSpan={staticReadingRows[0].length - 2 + 1}
                style={{ fontWeight: "400" }}
              >
                <b>Expanded Uncertainty ± : </b>
                {staticCertificateReadings.reduce(
                  (a, b) =>
                    Math.max(
                      parseFloat(a.uncertainty) ? parseFloat(a.uncertainty) : 0,
                      parseFloat(b.uncertainty) ? parseFloat(b.uncertainty) : 0
                    ),
                  -Infinity
                )}
              </td>
            </tr>,
          ]
        : []
    );
  };

  React.useEffect(() => {
    initiateStaticReadingRows();
  }, [staticCertificateReadings, staticTables, certificateId]);

  React.useEffect(() => {
    fetchStaticReadingRows();
  }, [certificateId]);

  React.useEffect(() => {
    fetchCertificateStaticTables(instrumentId, deletedTableIds);
  }, [instrumentId, deletedTableIds, certificateId]);

  if (!staticTables) return null;

  let validTables = staticTables
    ?.filter((table) => table.status == 1)
    ?.map((table, tableIndex) => {
      if (tableDisplayStatus === 1 && table.isAutomated !== 0) return null;
      else if (tableDisplayStatus === 2 && table.isAutomated !== 1) return null;

      return table;
    })
    ?.filter((t) => t !== null);

  let split1 = 0;
  let second = false;
  return validTables.map((table, tableIndex) => {
    let i = 0;

    let resd = tableIndex % 2;

    let col = second ? 6 : 12;
    if (second) second = false;
    else if (resd === split1) {
      if (tableIndex !== validTables.length - 1) {
        let len =
          Array.from({ length: MAX_COLS + 1 }, (_, i) => {
            if (validTables[tableIndex]["c" + i] != null) {
              return true;
            }
          }).filter((e) => e === true).length +
          (tableDivide ? 0 : 1) +
          (validTables[tableIndex]["isUncertainty"] == 1 ? 1 : 0) +
          (validTables[tableIndex]["isComplianceStatus"] == 1 ? 1 : 0) +
          Array.from({ length: MAX_COLS + 1 }, (_, i) => {
            if (validTables[tableIndex + 1]["c" + i] != null) {
              return true;
            }
          }).filter((e) => e === true).length +
          (tableDivide ? 0 : 1) +
          (validTables[tableIndex + 1]["isUncertainty"] == 1 ? 1 : 0) +
          (validTables[tableIndex + 1]["isComplianceStatus"] == 1 ? 1 : 0);

        if (len < (tableDivide ? 9 : 6)) {
          second = true;
          col = 6;
        } else {
          split1 += 1;
          split1 %= 2;
        }
      }
    }

    let showHeader = table?.defaultConfiguration?.showHeader;
    showHeader = showHeader === undefined ? true : showHeader;

    return (
      <div
        key={tableIndex}
        align="left"
        style={{
          marginBottom: "3px",
          pageBreakInside:
            (tableIndex === 0 && (col === 6 || col === 12)) || (tableIndex === 1 && col === 6) ||
            (tableIndex < 1 && col === 6)
              ? "auto"
              : "avoid"
        }}
        className={col === 6 ? "col-6" : "col-12"}
      >
        <b className="standardUnitTable">
          {configuration?.customTableNames?.[table.id] || table.tableName}
        </b>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ClassicTable>
            <table
              style={{ padding: "0px" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="single-border-table"
            >
              {showHeader && renderCommonHeader(table?.defaultConfiguration)}

              {showHeader ? (
                <tr
                  style={{
                    pageBreakInside: "avoid",
                    pageBreakAfter: "avoid",
                    pageBreakBefore: "avoid",
                  }}
                >
                  <td
                    className="header-border tableCss header-border-top-none common-header"
                    align="center"
                  >
                    <b className="standardUnitTable">Sr.No.</b>
                  </td>
                  {/* render columns*/}
                  {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                    if (table["c" + i] != null) {
                      return (
                        <td
                          className="header-border tableCss header-border-top-none common-header"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <b>{table["c" + i]} </b>
                        </td>
                      );
                    }
                  })}
                  {table["isUncertainty"] == 1 &&
                    !staticTables[tableIndex].isVerticalUncertainty && (
                      <td
                        className="header-border tableCss black header-border-top-none common-header"
                        align="center"
                        // style={{ textAlign: 'center', fontWeight: '600', width: "100px" }}
                        style={{ textAlign: "center" }}
                      >
                        <b>
                          {"Expanded Uncertainty (±) "}
                        </b>
                        {/* </Typography> */}
                      </td>
                    )}
                    {table["isComplianceStatus"] == 1 && (
                        <td
                          className="header-border tableCss black header-border-top-none common-header"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <b>
                            {"Compliance Status"}
                          </b>
                        </td>
                      )}
                </tr>
              ) : (
                ""
              )}
              {renderRows(table, staticReadingRows, tableIndex)}
            </table>
          </ClassicTable>
        </div>
      </div>
    );
  });
}
